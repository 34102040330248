import * as React from "react";
import {Parallax} from "react-scroll-parallax";
import Markt from "./Markt";

const Landing24 = ({items}) => {

    return (
        <div className="landing__outer landing__outer24">
            <p className={"small eintrittfrei"}>Eintritt frei</p>
            <div className="landing__inner landing24">
                <div className={"landing24__title"}>
                    <h1>Markt der Zukunft</h1>
                    <h4 className={"landing24__klimakultur"}>Das Klimakulturfestival</h4>
                </div>
                <div className="landing24__maerkte">
                    {items?.map((item, index) => {
                        return <div key={index} className={"landing24__markt"}>
                            <Markt el={item}/>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Landing24;


/*
<div className="landing__headlines__container">
                    <Parallax className={"desktop"} translateX={[10, -30]}>
                        <h1>Markt der Zukunft</h1>
                    </Parallax>
                    <Parallax className={"desktop"} translateX={[-20, 50]}>
                        <h4>Das Klimakulturfestival</h4>
                    </Parallax>
                    <Parallax className={"desktop"} translateX={[-10, 50]}>
                        <h4>{date}</h4>
                    </Parallax>
                    <Parallax className={"desktop"} translateX={[0, 50]}>
                        <h4>{date2}</h4>
                    </Parallax>
                    <h1 className={"mobile"}>Markt der Zukunft</h1>
                    <h4 className={"mobile"}>Das Klimakulturfestival <br/>
                        <span className={""}>{date}</span>
                        <h4 className="uppercase landing">
                            {location5} <br/>
                            {location6} <br/>
                            {location7} <br/>
                        </h4>
                        <br/>
                        <span className={""}>{date2}</span>
                        <h4 className="uppercase landing">
                            {location1} <br/>
                            {location2} <br/>
                            {location3} <br/>
                            {location4}
                        </h4>
                    </h4>
                </div>
                <Parallax speed={10} className={"landing__year desktop"}>
                    {year === '22' ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="749" height="499.334" viewBox="0 0 749 499.334">
                        <g className={"svg-fill-dark"} id="Group_152" data-name="Group 152" transform="translate(0)">
                            <path id="Path_260" data-name="Path 260" d="M912.419,444.128l218.019-203.954q16.878-16.17,26.022-32.7,9.129-16.513,9.143-37.627,0-31.648-19.342-50.988t-61.537-19.34q-34.466,0-56.613,19.34t-27.782,50.988h-87.91a207.111,207.111,0,0,1,16.526-58.725A143.181,143.181,0,0,1,962.7,64.352q21.447-19.683,52.044-30.945,30.6-11.239,69.979-11.252,42.2,0,74.55,10.548t53.8,29.89a129.254,129.254,0,0,1,32.7,45.714q11.242,26.372,11.252,58.021a125.5,125.5,0,0,1-5.274,36.923,161.73,161.73,0,0,1-14.065,32.7,177.756,177.756,0,0,1-20.395,28.835,337.172,337.172,0,0,1-23.561,24.616L1031.977,444.128h232.085v77.361H912.419Z" transform="translate(-912.419 -22.155)"/>
                            <path id="Path_261" data-name="Path 261" d="M1116.216,444.128l218.02-203.954q16.878-16.17,26.023-32.7,9.128-16.513,9.14-37.627,0-31.648-19.339-50.988t-61.539-19.34q-34.465,0-56.614,19.34t-27.78,50.988h-87.911a207.139,207.139,0,0,1,16.528-58.725A143.113,143.113,0,0,1,1166.5,64.352q21.445-19.683,52.042-30.945,30.6-11.239,69.979-11.252,42.2,0,74.549,10.548t53.8,29.89a129.191,129.191,0,0,1,32.7,45.714q11.246,26.372,11.252,58.021a125.461,125.461,0,0,1-5.273,36.923,161.546,161.546,0,0,1-14.066,32.7,177.835,177.835,0,0,1-20.394,28.835,337.406,337.406,0,0,1-23.562,24.616L1235.775,444.128H1467.86v77.361H1116.216Z" transform="translate(-718.86 -22.155)"/>
                        </g>
                    </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="760.07" height="502" viewBox="0 0 760.07 502">
                            <path id="Path_3" className="svg-fill-dark" d="M29.6,638.825,248.783,433.783q16.969-16.262,26.161-32.877t9.192-37.827q0-31.817-19.444-51.261t-61.866-19.444q-34.645,0-56.917,19.444T117.98,363.079H29.6a208.384,208.384,0,0,1,16.615-59.038,143.976,143.976,0,0,1,33.938-47.018q21.565-19.8,52.321-31.11T202.825,214.6q42.423,0,74.946,10.606t54.089,30.049a129.771,129.771,0,0,1,32.877,45.958q11.313,26.514,11.313,58.331a126.184,126.184,0,0,1-5.3,37.12,162.8,162.8,0,0,1-14.141,32.877,178.827,178.827,0,0,1-20.5,28.989,340.792,340.792,0,0,1-23.686,24.746L149.8,638.825H383.121V716.6H29.6Zm608.056-24.746H400.8V536.3L605.839,221.67h120.2V536.3H789.67v77.775H726.037V716.6h-88.38Zm0-77.775V317.121h-3.535L492.713,536.3Z" transform="translate(-29.6 -214.6)"/>
                        </svg>

                    }
                </Parallax>
                <div className="landing__image">
                    <div className="landing__image__inner">
                        <ParallaxBanner>
                            <ParallaxBannerLayer speed={5}>
                                <GatsbyImage objectPosition={"center"} image={image} alt={"Das Klimakulturfestival"}></GatsbyImage>
                            </ParallaxBannerLayer>
                        </ParallaxBanner>
                        <p className="credits__container small landing">FOTO: {credits}</p>
                    </div>
                </div>
                <div className="landing__location">
                    {year === '22' ? <h4 className="uppercase landing">
                        Alte Universität Graz
                    </h4> : <h4 className="uppercase landing">
                        {location5} <br/>
                        {location6} <br/>
                        {location7} <br/>
                        --- <br/>
                        {location1} <br/>
                        {location2} <br/>
                        {location3} <br/>
                        {location4}
                    </h4>}

                </div>
                <div className="mobile__landing__bottom mobile">
                    {year === '22' ?
                    <svg className={"mobile__year"} xmlns="http://www.w3.org/2000/svg" width="749" height="499.334" viewBox="0 0 749 499.334">
                        <g className={"svg-fill-dark"} id="Group_152" data-name="Group 152" transform="translate(0)">
                            <path id="Path_260" data-name="Path 260" d="M912.419,444.128l218.019-203.954q16.878-16.17,26.022-32.7,9.129-16.513,9.143-37.627,0-31.648-19.342-50.988t-61.537-19.34q-34.466,0-56.613,19.34t-27.782,50.988h-87.91a207.111,207.111,0,0,1,16.526-58.725A143.181,143.181,0,0,1,962.7,64.352q21.447-19.683,52.044-30.945,30.6-11.239,69.979-11.252,42.2,0,74.55,10.548t53.8,29.89a129.254,129.254,0,0,1,32.7,45.714q11.242,26.372,11.252,58.021a125.5,125.5,0,0,1-5.274,36.923,161.73,161.73,0,0,1-14.065,32.7,177.756,177.756,0,0,1-20.395,28.835,337.172,337.172,0,0,1-23.561,24.616L1031.977,444.128h232.085v77.361H912.419Z" transform="translate(-912.419 -22.155)"/>
                            <path id="Path_261" data-name="Path 261" d="M1116.216,444.128l218.02-203.954q16.878-16.17,26.023-32.7,9.128-16.513,9.14-37.627,0-31.648-19.339-50.988t-61.539-19.34q-34.465,0-56.614,19.34t-27.78,50.988h-87.911a207.139,207.139,0,0,1,16.528-58.725A143.113,143.113,0,0,1,1166.5,64.352q21.445-19.683,52.042-30.945,30.6-11.239,69.979-11.252,42.2,0,74.549,10.548t53.8,29.89a129.191,129.191,0,0,1,32.7,45.714q11.246,26.372,11.252,58.021a125.461,125.461,0,0,1-5.273,36.923,161.546,161.546,0,0,1-14.066,32.7,177.835,177.835,0,0,1-20.394,28.835,337.406,337.406,0,0,1-23.562,24.616L1235.775,444.128H1467.86v77.361H1116.216Z" transform="translate(-718.86 -22.155)"/>
                        </g>
                    </svg>
                        : <svg className={"mobile__year"} xmlns="http://www.w3.org/2000/svg" width="760.07" height="502" viewBox="0 0 760.07 502">
                            <path id="Path_3" className="svg-fill-dark" d="M29.6,638.825,248.783,433.783q16.969-16.262,26.161-32.877t9.192-37.827q0-31.817-19.444-51.261t-61.866-19.444q-34.645,0-56.917,19.444T117.98,363.079H29.6a208.384,208.384,0,0,1,16.615-59.038,143.976,143.976,0,0,1,33.938-47.018q21.565-19.8,52.321-31.11T202.825,214.6q42.423,0,74.946,10.606t54.089,30.049a129.771,129.771,0,0,1,32.877,45.958q11.313,26.514,11.313,58.331a126.184,126.184,0,0,1-5.3,37.12,162.8,162.8,0,0,1-14.141,32.877,178.827,178.827,0,0,1-20.5,28.989,340.792,340.792,0,0,1-23.686,24.746L149.8,638.825H383.121V716.6H29.6Zm608.056-24.746H400.8V536.3L605.839,221.67h120.2V536.3H789.67v77.775H726.037V716.6h-88.38Zm0-77.775V317.121h-3.535L492.713,536.3Z" transform="translate(-29.6 -214.6)"/>
                        </svg>}
                    <div className="btn__inner light icon--small" style={{opacity: 0}}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="10.449" height="13.398" viewBox="0 0 10.449 13.398">
                            <g className={"svg-path-dark svg-path"} id="Group_47" data-name="Group 47" transform="translate(0.707)">
                                <path id="Path_82" data-name="Path 82" d="M-852.781,666.193v10.725" transform="translate(857.298 -666.193)"/>
                                <path id="Path_84" data-name="Path 84" d="M-857.731,675.45l4.517,4.517,4.517-4.517" transform="translate(857.731 -667.983)"/>
                            </g>
                        </svg>
                    </div>
                </div>
 */
