import {GatsbyImage} from "gatsby-plugin-image";
import MyPortableText from "./MyPortableText";
import React, {useRef, useState} from "react";
import Link from "gatsby-link";
import {useBreakpoint} from "gatsby-plugin-breakpoints";

const Initiative = ({el}) => {

    return (
        <div className="initiative__einzel__row reverse">
            <div className={"initiative__flexspacer"}>
                <div className={"iniativentext__wrapper"}>
                    <p className="demibold">{el.title}</p>
                    <MyPortableText value={el._rawBody}></MyPortableText>
                </div>
                <div className="socialbtn__wrapper mobilemargin">
                    <div className={"socialbtn__container"}>
                        {el.fb ? <a href={el.fb} target="_blank" className="btn__inner light social">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.772" height="22.79" viewBox="0 0 12.772 22.79">
                                <path className={"svg-path svg-path-dark"} id="Path_229" data-name="Path 229" d="M2673.726,1218.01h-3.811c0-.087-.01-.175-.01-.263v-9.227h-3.176v-3.707h3.176v-.248c0-.778-.016-1.558.006-2.336a9.211,9.211,0,0,1,.137-1.491,4.17,4.17,0,0,1,3.672-3.457,14.919,14.919,0,0,1,2.791-.008c.329.017.657.065.985.1v3.29c-.817.012-1.634.006-2.45.042a1.252,1.252,0,0,0-1.248.994,1.926,1.926,0,0,0-.07.479c-.01.833-.009,1.665-.011,2.5a1.314,1.314,0,0,0,.022.155h3.625l-.477,3.7h-3.172v.244q0,4.507,0,9.016C2673.716,1217.862,2673.723,1217.937,2673.726,1218.01Z" transform="translate(-2665.729 -1196.22)"/>
                            </svg>
                        </a> : <></>}
                        {el.insta ? <a href={el.insta} target="_blank" className="btn__inner light social">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.12" height="23.116" viewBox="0 0 23.12 23.116">
                                <g className={"svg-path svg-path-dark"} id="Group_127" data-name="Group 127" transform="translate(-2693.769 -1195.39)">
                                    <path id="Path_226" data-name="Path 226" d="M2710.527,1217.505h-10.393a11.641,11.641,0,0,1-1.323-.281,5.756,5.756,0,0,1-4.035-5.535c-.013-3.162,0-6.323,0-9.485a5.5,5.5,0,0,1,.725-2.82,5.683,5.683,0,0,1,5.153-2.994c3.12-.02,6.24-.008,9.359,0a5.507,5.507,0,0,1,1.167.107,5.7,5.7,0,0,1,4.537,4.3c.086.308.118.632.175.949v10.4c-.035.216-.058.435-.106.647a5.721,5.721,0,0,1-4.31,4.542C2711.165,1217.418,2710.843,1217.449,2710.527,1217.505Z" transform="translate(0 0)"/>
                                    <path id="Path_227" data-name="Path 227" d="M2709.924,1206.2a5.336,5.336,0,1,1-5.328-5.337A5.347,5.347,0,0,1,2709.924,1206.2Z" transform="translate(0.742 0.743)"/>
                                    <path id="Path_228" data-name="Path 228" d="M2709.225,1199.331a1,1,0,0,0-.977,1.008,1.026,1.026,0,0,0,.985.967,1,1,0,0,0,.99-1A.987.987,0,0,0,2709.225,1199.331Z" transform="translate(2.231 0.489)"/>
                                </g>
                            </svg>
                        </a> : <></>}
                        {el.linkedin ?
                            <a href={el.linkedin} target="_blank" className="btn__inner light social icon--small">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.494" height="13.395" viewBox="0 0 21.494 13.395">
                                    <g className={"svg-fill-dark"} id="Group_187" data-name="Group 187" transform="translate(493 -129.811)">
                                        <path id="Path_280" data-name="Path 280" d="M-493,142.871V129.814c2.1,0,4.2-.011,6.3.008a7.975,7.975,0,0,1,1.579.174,3.1,3.1,0,0,1,2.232,1.612,3.608,3.608,0,0,1,.311,2.22,2.468,2.468,0,0,1-1.322,1.9c-.074.041-.146.086-.24.141l.257.115a2.815,2.815,0,0,1,1.4,1.291,3.531,3.531,0,0,1,.353,1.988,3.69,3.69,0,0,1-1.953,3.055,5.189,5.189,0,0,1-2.538.544C-488.746,142.884-490.873,142.87-493,142.871Zm2.656-2.261h.237c.937,0,1.873,0,2.81,0,.279,0,.559-.016.836-.044a1.547,1.547,0,0,0,1.5-1.287,3.134,3.134,0,0,0,.019-.871,1.241,1.241,0,0,0-.7-1,2.435,2.435,0,0,0-1.132-.262c-1.139-.013-2.278-.009-3.418-.011-.047,0-.094.007-.15.011Zm0-5.657c.055,0,.089.008.123.008,1.056,0,2.113.012,3.169-.009a4.185,4.185,0,0,0,.987-.162,1.069,1.069,0,0,0,.764-.63,1.814,1.814,0,0,0,.1-.987,1.053,1.053,0,0,0-.862-.935,3.739,3.739,0,0,0-.721-.121c-1.154-.024-2.308-.029-3.461-.04a.418.418,0,0,0-.1.023Z" transform="translate(0)"/>
                                        <path id="Path_281" data-name="Path 281" d="M-208.686,214.834a11.933,11.933,0,0,1-1.2-.261,4.429,4.429,0,0,1-2.611-1.938,4.683,4.683,0,0,1-.576-1.841,7.231,7.231,0,0,1,.194-2.962,4.233,4.233,0,0,1,3.31-3.093,5.518,5.518,0,0,1,3.854.436,4.007,4.007,0,0,1,1.973,2.342,11.3,11.3,0,0,1,.311,1.485c.012.06.024.121.036.181V210.4c-.035,0-.069-.009-.1-.009h-6.864c-.054,0-.108.008-.161.012a2.139,2.139,0,0,0,1.742,2.305,2.209,2.209,0,0,0,2.569-.923.305.305,0,0,1,.226-.095c.651-.007,1.3,0,1.952,0h.554a7.346,7.346,0,0,1-.4.936,4.318,4.318,0,0,1-3,2.087c-.238.047-.478.085-.717.127Zm2.6-6.122a2.022,2.022,0,0,0-2.157-2.038,2.075,2.075,0,0,0-2.216,2.038Z" transform="translate(-268.109 -71.628)"/>
                                        <path id="Path_282" data-name="Path 282" d="M-159.825,146.411h-5.669v-1.595h5.669Z" transform="translate(-313.757 -14.375)"/>
                                    </g>
                                </svg>
                            </a> : <></>}
                        {el.website ?
                            <a href={el.website} target="_blank" className="btn__inner social light">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.137" height="21.137" viewBox="0 0 21.137 21.137">
                                    <path className={"svg-fill-dark"} id="Path_286" data-name="Path 286" d="M293.949,284h1.238c.279.033.558.062.836.1a10.575,10.575,0,0,1,8.951,8.649c.069.4.109.8.162,1.2v1.238c-.013.073-.028.145-.038.218-.05.381-.08.766-.152,1.143a10.568,10.568,0,0,1-8.558,8.425c-.4.069-.8.109-1.2.163h-1.238c-.279-.033-.558-.062-.836-.1a10.575,10.575,0,0,1-8.95-8.649c-.069-.4-.109-.8-.162-1.2v-1.238c.013-.073.028-.145.038-.218.05-.381.08-.766.151-1.144a10.569,10.569,0,0,1,8.558-8.425C293.145,284.094,293.548,284.054,293.949,284Zm-.014,9.94v-3.107l-3.561-.246a16.408,16.408,0,0,0-.436,3.353Zm1.263,0h4a16.225,16.225,0,0,0-.43-3.323l-3.57.225Zm-6.562,0,.449-3.54c-.766-.19-1.553-.38-2.335-.585-.14-.037-.2-.019-.275.108a8.848,8.848,0,0,0-.962,2.423c-.117.519-.188,1.049-.282,1.594Zm13.981,5.349a9.175,9.175,0,0,0,1.253-4.087H300.5l-.448,3.482Zm1.255-5.35a9.171,9.171,0,0,0-1.254-4.08l-2.56.571.441,3.509Zm-5.111,4.548a16.342,16.342,0,0,0,.435-3.284h-4v3.039ZM293.94,295.2h-4a16.06,16.06,0,0,0,.435,3.26l3.567-.223Zm-5.3,0h-3.366c0,.051,0,.091,0,.13a9.217,9.217,0,0,0,1.156,3.811c.065.117.136.1.236.074.708-.165,1.417-.325,2.125-.486l.29-.065Zm9.808-5.8c-.244-.55-.442-1.048-.683-1.524a5.88,5.88,0,0,0-1.493-1.95,2.711,2.711,0,0,0-1.07-.574v4.242Zm-7.757,10.287c.238.538.433,1.029.669,1.5a5.97,5.97,0,0,0,1.473,1.941,2.779,2.779,0,0,0,1.1.6v-4.234Zm3.245-14.342a3.188,3.188,0,0,0-1.409.885,7.617,7.617,0,0,0-1.55,2.484c-.086.206-.159.417-.252.662l3.211.213Zm1.262,18.387a3.19,3.19,0,0,0,1.412-.889,7.66,7.66,0,0,0,1.541-2.465c.087-.205.158-.417.254-.671l-3.207-.21Zm-7.974-3.4a9.063,9.063,0,0,0,3.95,2.909,11.469,11.469,0,0,1-1.709-3.379Zm10.734,2.91a9.066,9.066,0,0,0,3.91-2.861l-2.211-.488A11.473,11.473,0,0,1,297.96,303.231Zm.053-17.312a11.362,11.362,0,0,1,1.66,3.307l2.2-.46A9.151,9.151,0,0,0,298.013,285.919Zm-6.89,0a9.121,9.121,0,0,0-3.813,2.793l2.17.475A11.262,11.262,0,0,1,291.124,285.919Z" transform="translate(-284 -284)"/>
                                </svg>
                            </a>
                            : <></>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Initiative;
