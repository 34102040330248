import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import {Link} from "gatsby";
import LandingNewsletter from "../components/LandingNewsletter";
import {graphql} from 'gatsby';
import MyPortableText from "../components/MyPortableText";
import InitiativeLanding from "../components/InitiativeLanding";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import {useEffect, useRef, useState} from "react";
import ImageGallery from "react-image-gallery";
import Landing24 from "../components/Landing24";
import Partner from "../components/Partner";
export const query = graphql`
	{
		allSanityMdz24 {
			edges {
				node {
					markte{
						title
						datum
						locations
						image{ asset{ gatsbyImageData}}
						credits
						button{
							link
							title
						}
					}
					_rawIntrotext
					initiativen{
						fb
						insta
						linkedin
						website
						title
						_rawBody
					}
					archive{
						credits
						image{ asset{ gatsbyImageData}}
						title
						_rawText
						link
						linktext
					}
					images{ asset{ gatsbyImageData
						url}}
					galeriecredits
				}

			}
		}
	}
`;

let images = [];

const PrePage = ({data}) => {

	const page = data.allSanityMdz24.edges[0].node;

	useEffect(() => {
		console.log("useEffect");
		console.log(page.images);
		page.images.map((el) => {
			const image = {
				original: el?.asset?.url,
				thumbnail: el?.asset?.url
			}
			images.push(image);
		})
	});

	return (
		<Layout newsletter={true}>
			<SEO/>
			<Landing24 items={page?.markte}></Landing24>
			<div className="intro__outer intro__outer__alt" id={"about"}>
				<div className="intro__inner">
					<div className="intro__text__container">
						<h3 className={"intro__text half"}>
							<MyPortableText value={page._rawIntrotext}></MyPortableText>
						</h3>
					</div>
				</div>
			</div>
			{images.length > 0 ? <div className="section__outer">
				<div className="section__inner" id={"programm"}>
					<h4 className={"uppercase box"}>Galerie</h4>
					<div className="separator"></div>
					<ImageGallery items={images}/>
					<p className="small uppercase">FOTOS: {page.galeriecredits}</p>
				</div>
			</div> : <></>}
			<div className="section__outer">
				<div className="section__inner">
					<h4 className={"uppercase"}>Newsletter</h4>
					<div className="separator"></div>
					<div className="coll__outer">
						<div className="coll3">
							<p className={"smallwidth col3"}>
								Bleib am aktuellen Stand was Nachhaltigkeit, den <span className={"demibold"}>Markt der Zukunft</span> und
								die <a href="/initiativen" className={"underline"}>Initiativen</a> dahinter
								betrifft.
							</p>
						</div>
						<div className="coll3">
							<div className="newsletter__form">
								<LandingNewsletter></LandingNewsletter>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section__outer section__initiativen">
				<div className="section__inner" id={"initiativen"}>
					<h4 className={"uppercase box"}>initiativen</h4>
					<div className="separator"></div>
					<div className="initiativen">
						{page.initiativen.map((el, index) => (
							<InitiativeLanding el={el}></InitiativeLanding>
						))}
					</div>
					<div className="btn__outer">
						<Link to={"/initiativen"} className="btn__inner light icon--small">
							INITIATIVEN
							<svg xmlns="http://www.w3.org/2000/svg" width="10.449" height="13.398" viewBox="0 0 10.449 13.398">
								<g className={"svg-path-dark svg-path"} id="Group_47" data-name="Group 47" transform="translate(0.707) rotate(-90)" style={{
									transformOrigin: "center",
									marginTop: "-15px"
								}}>
									<path id="Path_82" data-name="Path 82" d="M-852.781,666.193v10.725" transform="translate(857.298 -666.193)"/>
									<path id="Path_84" data-name="Path 84" d="M-857.731,675.45l4.517,4.517,4.517-4.517" transform="translate(857.731 -667.983)"/>
								</g>
							</svg>
						</Link>
					</div>
				</div>
			</div>
			<div className="section__outer">
				<div className="section__inner" id={"archiv"}>
					<h4 className={"uppercase box"}>archiv</h4>
					<div className="separator"></div>
					<div className="website__wrapper">
						{page.archive.map((el, index) => {
							return (<div className={index === 0 ? "website mdz23" :  index === 1 ? "website mdz22" : "website mdz21"}>
								<h2>{el.title}</h2>
								<div className="content">
									<div className="description">
										<MyPortableText value={el._rawText}></MyPortableText>
									</div>
									<div className="image">
										<GatsbyImage image={el.image.asset.gatsbyImageData} alt={el.credits}></GatsbyImage>
										<p className="credits smallest landing">FOTO: {el.credits}</p>
									</div>
								</div>
								<div className="btn__outer">
									<Link to={el.link} className="btn__inner light icon--small">
										{el.linktext}
										<svg xmlns="http://www.w3.org/2000/svg" width="10.449" height="13.398" viewBox="0 0 10.449 13.398">
											<g className={"svg-path-dark svg-path"} id="Group_47" data-name="Group 47" transform="translate(0.707) rotate(-90)" style={{
												transformOrigin: "center",
												marginTop: "-15px"
											}}>
												<path id="Path_82" data-name="Path 82" d="M-852.781,666.193v10.725" transform="translate(857.298 -666.193)"/>
												<path id="Path_84" data-name="Path 84" d="M-857.731,675.45l4.517,4.517,4.517-4.517" transform="translate(857.731 -667.983)"/>
											</g>
										</svg>
									</Link>
								</div>

							</div>);
						})}
					</div>
				</div>
			</div>
			<Partner/>
		</Layout>
	);
}

export default PrePage


/*
<Marquee speed={100} gradient={false}>
<div className={"marqueeText"}>
<h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
<h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
<h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
<h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
<h1 className="marqueeText__inner">Newsletter <div className="marquee__image"><StaticImage src={"../images/newsletter.jpg"} alt={"Newsletter"}></StaticImage></div></h1>
</div>
</Marquee>


                        <!--<GatsbyImage image={page.image.asset.gatsbyImageData} alt={"Markt der Zukunft - Keyvisual"} placeholder={"dominantColor"}></GatsbyImage>-->

*/
