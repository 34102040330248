import {GatsbyImage} from "gatsby-plugin-image";
import MyPortableText from "./MyPortableText";
import React, {useRef, useState} from "react";
import {ParallaxBanner, ParallaxBannerLayer} from "react-scroll-parallax";
import {Link} from "gatsby";

const Markt = ({el}) => {

    return (
        <div>
            <div className={"markt24__title"}>
                <h2>
                    {el.title}
                </h2>
                <h2>
                    {el.datum}
                </h2>
                    {el?.locations?.map((location, index) => {
                        return <h5 className={"locations24 uppercase"}>{location}</h5>
                    })}
            </div>
            <div className="markt24__image">
                <GatsbyImage objectPosition={"center"} image={el?.image?.asset?.gatsbyImageData} alt={el?.credits}></GatsbyImage>
                <p className="credits__container small landing">FOTO: {el?.credits}</p>
            </div>
            <div className="markt24__btn btn__outer">
                <Link to={el?.button?.link} className="btn__inner light icon--small">
                    {el?.button?.title}
                    <svg xmlns="http://www.w3.org/2000/svg" width="10.449" height="13.398" viewBox="0 0 10.449 13.398">
                        <g className={"svg-path-dark svg-path"} id="Group_47" data-name="Group 47" transform="translate(0.707) rotate(-90)" style={{
                            transformOrigin: "center",
                            marginTop: "-15px"
                        }}>
                            <path id="Path_82" data-name="Path 82" d="M-852.781,666.193v10.725" transform="translate(857.298 -666.193)"/>
                            <path id="Path_84" data-name="Path 84" d="M-857.731,675.45l4.517,4.517,4.517-4.517" transform="translate(857.731 -667.983)"/>
                        </g>
                    </svg>
                </Link>
            </div>
        </div>
    );
}

export default Markt;
